const img = [
  // {
  //   id: 1,
  //   src: "/black.jpg",
  // },
  // {
  //   id: 2,
  //   src: "/black.jpg",
  // },
  {
    id: 1,
    src: "/Ship/Artenix_Studios_Ship_01.png",
  },
  {
    id: 2,
    src: "/Ship/Artenix_Studios_Ship_02.png",
  },
  {
    id: 3,
    src: "/Ship/Artenix_Studios_Ship_03.png",
  },

  {
    id: 4,
    src: "/Ship/Artenix_Studios_Ship_04.png",
  },
  {
    id: 5,
    src: "/Ship/Artenix_Studios_Ship_05.png",
  },
  {
    id: 6,
    src: "/Ship/Artenix_Studios_Ship_06.png",
  },
  {
    id: 6,
    src: "/Ship/Artenix_Studios_Ship_07.png",
  },
  {
    id: 7,
    src: "/Car/Artenix_Studios_Car_01.png",
  },
  {
    id: 8,
    src: "/Car/Artenix_Studios_Car_02.png",
  },

  {
    id: 9,
    src: "/Car/Artenix_Studios_Car_03.png",
  },
  {
    id: 10,
    src: "/Car/Artenix_Studios_Car_04.png",
  },
  {
    id: 11,
    src: "/Car/Artenix_Studios_Car_05.png",
  },
  {
    id: 12,
    src: "/Car/Artenix_Studios_Car_06.png",
  },
  {
    id: 13,
    src: "/Car/Artenix_Studios_Car_07.png",
  },
  {
    id: 14,
    src: "/Car/Artenix_Studios_Car_08.png",
  },
  {
    id: 15,
    src: "/Car/Artenix_Studios_Car_09.png",
  },
  {
    id: 16,
    src: "/Car/Artenix_Studios_Car_10.png",
  },
  {
    id: 17,
    src: "/Car/Artenix_Studios_Car_11.png",
  },
  {
    id: 19,
    src: "/Car/Artenix_Studios_Car_12.png",
  },
  {
    id: 20,
    src: "/Car/Artenix_Studios_Car_13.png",
  },
  {
    id: 21,
    src: "/Car/Artenix_Studios_Car_14.png",
  },
  {
    id: 22,
    src: "/Car/Artenix_Studios_Car_15.png",
  },
  {
    id: 23,
    src: "/Car/Artenix_Studios_Car_16.png",
  },
  {
    id: 24,
    src: "/Car/Artenix_Studios_Car_17.png",
  },
  {
    id: 25,
    src: "/Gun/Artenix_Studios_Gun_01.png",
  },
  {
    id: 26,
    src: "/Gun/Artenix_Studios_Gun_02.png",
  },
  {
    id: 27,
    src: "/Gun/Artenix_Studios_Gun_03.png",
  },
  {
    id: 28,
    src: "/Ipod/Artenix_Studios_Ipod_01.png",
  },
  {
    id: 29,
    src: "/Jeep/Artenix_Studios_Jeep_01.png",
  },
  {
    id: 30,
    src: "/Jeep/Artenix_Studios_Jeep_02.png",
  },
  {
    id: 31,
    src: "/Jeep/Artenix_Studios_Jeep_03.png",
  },
  {
    id: 32,
    src: "/Jeep/Artenix_Studios_Jeep_04.png",
  },
  {
    id: 33,
    src: "/Jeep/Artenix_Studios_Jeep_05.png",
  },
  {
    id: 34,
    src: "/Jeep/Artenix_Studios_Jeep_06.png",
  },
  {
    id: 35,
    src: "/Jeep/Artenix_Studios_Jeep_07.png",
  },
  {
    id: 35,
    src: "/Pump/Artenix_Studios_Pump_01.png",
  },
];

export default img;
