import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaPinterest,
  FaInstagram,
} from "react-icons/fa";

export default class navbar extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg bg-dark trn sticky-nav"
        id="tra-nav"
      >
        <div className="container">
          <div
            className="navbar-brand"
            onContextMenu={(e) => e.preventDefault()}
          >
            <Link to="/">
              <img
                className="artenix-logo"
                src="/images/logo.png"
                alt="artenix-logo"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav artenix-navbar mr-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#contact">
                  Contact Us
                </Link>
              </li>
            </ul>
            <section id="social-icons">
              <ul className="navbar-nav ml-5 mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    href="https://www.facebook.com/art.enix.5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FaFacebook />
                  </a>
                </li>

                <li className="nav-item ml-2">
                  <a
                    href="https://www.linkedin.com/in/arteniex-studios-389a691b2/?originalSubdomain=in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FaLinkedin />
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </nav>
    );
  }
}
